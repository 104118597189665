import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { RouteComponentProps, WindowLocation } from '@reach/router';

import { getAuth, isAuthenticated } from '../state/selectors/auth';
import Loader from '../components/common/loader';
import PublicLayout from '../layout/public-layout';
import { navigateTo } from '../state/actions/routing';
import UserAuthContainer from '../containers/auth/user-auth';
import { getQueryParam } from '../utils/common';
import { getSignupLink } from '../apis/auth';
import { useQuery } from '../hooks/use-query';
import { Alert } from '../components';

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 30px;
`;

const useSignupLink = (location?: WindowLocation) => {
  const linkId = getQueryParam('link', location);
  const { isLoading, data, error } = useQuery(
    [getSignupLink.name, linkId],
    ({ queryKey: [, linkId] }) => (linkId ? getSignupLink(linkId as string) : Promise.resolve()),
    { retry: 0 }
  );
  return { linkId, isLoading, linkData: data, linkError: error as Error };
};

export default function Signup({ location }: RouteComponentProps) {
  const { loading } = useSelector(getAuth);
  const loggedIn = useSelector(isAuthenticated);
  const dispatch = useDispatch();
  const { pathname } = location || {};

  const { linkId, isLoading, linkData, linkError } = useSignupLink(location);

  useEffect(() => {
    let redirectPath;
    if (loggedIn && pathname !== '/myhsf') {
      redirectPath = '/myhsf';
    }

    if (redirectPath) {
      dispatch(navigateTo(redirectPath) as any);
    }
  }, [loggedIn, pathname, dispatch]);

  if (loading || isLoading) {
    return <Loader></Loader>;
  }

  if (linkId && linkError) {
    return (
      <PublicLayout>
        <Container>
          <p>Invalid Signup Link, please contact support</p>
          <Alert message="Reason:" type="error" showIcon={true} description={linkError.message}></Alert>
        </Container>
      </PublicLayout>
    );
  }

  return (
    <PublicLayout>
      <Container>
        <UserAuthContainer pathname={pathname || ''} initialEmail={linkData?.email} />
      </Container>
    </PublicLayout>
  );
}
